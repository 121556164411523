@import '../../../../../styles/colors';
@import '../../../../../styles/fonts';
@import '../../../../../styles/variables';


// Sub-links specific styles
.sidebar-sub-links {
    margin-left: 12px;
    position: relative;
    padding-left: 16px;
    margin-top: 8px;
    transition: max-height $standard-animation-speed ease, 
                opacity $standard-animation-speed linear;
    overflow: visible; // Changed from hidden to make dot visible
    max-height: 0;
    opacity: 0;

    // Continuous vertical line
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 8px;
        bottom: 8px;
        width: 1px;
        background-color: $cc-lime; // Changed line color
    }

    // Moving dot
    &::after {
        content: '';
        position: absolute;
        left: -3px;
        top: 0; // Reset initial position
        width: 7px;
        height: 7px;
        background-color: $cc-lime;
        border-radius: 50%;
        transition: transform 0.3s ease;
        z-index: 1;
    }

    &[data-active="client"] {
        &::after {
            transform: translateY(12px); // Adjusted to align with the first sub-link
        }

        .sub-link {
            &:first-child {
                color: $cc-lime;
            }
        }
    }

    &[data-active="server"] {
        &::after {
            transform: translateY(49px); // Adjusted to align with the second sub-link
        }

        .sub-link {
            &:last-child {
                color: $cc-lime;
            }
        }
    }

    .sub-link {
        display: block;
        padding: 8px 0;
        color: rgba(255, 255, 255, 0.7);
        text-decoration: none;
        transition: color 0.3s ease;
        font-size: $font-size-medium;
        line-height: 1.2;

        &:hover {
            color: rgba(255, 255, 255, 0.9);
        }

        &.active {
            color: $cc-lime;
        }
    }

    &.expanded {
        max-height: 100rem;
        opacity: 1;
    }
}

.sidebar-menu-category {
    .sidebar-category-container {
        cursor: pointer;
        overflow: hidden;
        display: inline-flex;
        align-items: center;
        gap: .4rem;
        padding-bottom: 0.7rem;
        transition: color $fast-animation-speed ease;

        &:hover {
            color: $cc-lime;
        }

        svg {
            margin: .21rem;
        }

        .sidebar-category-name {
            overflow: hidden;
            text-wrap: nowrap;
            font-weight: bold;
            font-size: $font-size-medium;
            letter-spacing: .045em;
        }
    }

    .sidebar-elems-container {
        display: block;
        position: relative;
        overflow: hidden;

        &.expanded {
            .sidebar-elems-grid {
                max-height: 100rem;
                opacity: 1;
            }
        }

        .sidebar-elems-grid {
            position: relative;
            transition: max-height $standard-animation-speed ease, opacity $standard-animation-speed linear;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            gap: 0.65rem;
            opacity: 0;
            max-height: 0;
            margin-left: 2.2rem;
            margin: 0;

            .sidebar-elems-link {
                text-decoration: none;
                color: $primary-color;
                letter-spacing: .045em;

                &:hover {
                    color: $cc-lime;
                }

                .parent-link {
                    &.expanded { // Add expanded state
                        color: $cc-lime;
                    }
                }

                &.disabled {
                    cursor: default;
                    pointer-events: none;
                    color: $cc-dark-grey;
                    opacity: .5;
                }
            }
        }
    }
}