@import '../../../../styles/colors';
@import '../../../../styles/variables';
@import '../../../../styles/fonts';

@keyframes icon-rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes icon-fast-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.sidebar-menu {
    position: fixed;
    float: left;
    left: 0;
    width: $sidebar-initial-width;
    background-color: $cc-dark-blue;
    color: $primary-color;
    height: 100%;
    transition: width $standard-animation-speed ease;
    overflow: hidden;
    z-index: $sidebar-z-index;

    font-size: $font-size-sidebar;

    &.hovered {
        width: $sidebar-full-width; // Set it to 100% to expand to the specified max-width

        .title-sliding {
            opacity: 1;
        }

        .sidebar-logo-icon {
            animation: icon-fast-spin $standard-animation-speed linear forwards;
        }
    }

    .sidebar-logo-icon {
        transition: transform $standard-animation-speed ease-in-out;
        animation: icon-rotate 20s linear infinite;
    }

    .title-sliding {
        position: relative;
        opacity: 0;
        transition: opacity $standard-animation-speed ease-in-out;
        text-wrap: nowrap;
    }

    .sidebar-container {
        height: 100%;
        display: grid;
        align-items: stretch;
        grid-template: 80vh 20vh / 100%;

        .sidebar-container-top {
            margin: 1.5rem;
            display: flex;
            row-gap: 3rem;
            flex-direction: column;

            .sidebar-logo-container {
                display: flex;
                align-items: center;
                gap: 0.5rem;
            }
        }

        .sidebar-elems-area {
            display: flex;
            flex-direction: column;
            gap: 2rem;

            &.links-area {
                height: 100%;
                overflow: auto;
                -webkit-overflow-scrolling: touch;
                scrollbar-width: none;
                min-height: 0;
                max-height: 100%;
                overflow-x: hidden;
            }
        }

        .sidebar-container-bottom {
            margin: 0 1.5rem 1.5rem;
            display: grid;
            align-items: flex-end;  
        }
    }
}